.mainHeader {
  position: relative;
  background: var(--dark-background);
  height: 50px;
  overflow: visible;

  @media (max-width: 768px) {
    height: 100px;
  }

  .headerElements {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 6fr 3fr 1fr 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    height: 100%;
    background: var(--dark-background);
    z-index: 90;
    @media (max-width: 1200px) {
      grid-template-columns: 2fr 8fr 2fr 1fr;
    }
    @media (max-width: 768px) {
      grid-template-columns: 5fr 3fr 1fr;
      grid-template-rows: 1fr 1fr;
      padding-bottom: 6px;
    }
    .logoWrapper {
      position: relative;
      display: block;
      grid-column: 1;
      grid-row: 1;
      width: 175px;
      height: 35px;
      margin-right: var(--spacing-sm);
      // Uncomment to add fadeIn animation to ease sudden content flash on load
      // animation: fadeIn 0.7s ease-in;
      @media (max-width: 1200px) {
        margin-left: var(--spacing-sm);
      }
      @media (max-width: 768px) {
        margin-left: 12px;
        margin-right: var(--spacing-xs);

        width: 132px;
        height: 27px;
      }
      .logoLink {
        display: block;
        position: relative;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          // width: 100%;
          // height: 100%;
        }
      }
    }
    .navLinks {
      grid-column: 2;

      @media (max-width: 1200px) {
        grid-column: none;
      }
    }
    .headerSearch {
      position: relative;
      grid-column: 3;
      grid-row: 1;
      justify-self: center;
      width: 100%;
      max-width: 600px;
      height: 30px;
      margin: 0 0 0 var(--spacing-xs);

      @media (max-width: 1200px) {
        grid-column: 2;
        margin-left: var(--spacing-lg);
      }
      @media (max-width: 992px) {
        margin-right: var(--spacing-lg);
      }
      @media (max-width: 768px) {
        grid-column: 1 / 5;
        grid-row: 2;
        margin-left: var(--spacing-xs);
        margin-right: var(--spacing-xs);
        height: 40px;
      }
    }
    .buttons {
      grid-column: 4 / 6;
      grid-row: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: var(--spacing-sm);

      @media (max-width: 1200px) {
        grid-column: 3;
      }
      @media (max-width: 768px) {
        grid-column: 2;
        justify-content: flex-end;
      }

      .button {
        margin: 0 var(--spacing-xxs);
      }

      .cartButton {
        position: relative;
        padding: var(--spacing-xxs);
        border-radius: var(--border-radius-sm);
        transition: var(--transition-md);
        &:hover {
          background: var(--accent-1);
        }

        .cartButtonCount {
          position: absolute;
          top: -5px;
          right: -5px;
          display: block;
          line-height: 1;
          text-align: center;
          width: 20px;
          height: 20px;
          padding: var(--spacing-xxs);
          background: var(--accent-1);
          border-radius: 50%;
          color: var(--light-text);
          font-size: 14px;
          font-weight: 700;
        }
      }
      .currencySwitcher {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        max-width: max-content;
        transition: var(--transition-md);
        // margin-left: var(--spacing-lg);

        @media (max-width: 1200px) {
          display: none;
          grid-column: 4;
          justify-self: right;
          // margin-right: var(--spacing-xxl);
        }
        @media (max-width: 768px) {
          grid-column: 2;
        }
        @media (max-width: 480px) {
          display: none;
        }
      }
      .favoritesButton {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 32px;
        min-width: 32px;
        height: 32px;
        // padding-top:3px;
        padding-left: 1px;
        transition: var(--transition-md);
        border-radius: var(--border-radius-md);

        &:hover {
          // transform: scale(1.1);
          background: var(--accent-1);
        }

        a {
          display: flex;
          padding: 0;
        }
      }
      .userButton {
        display: flex;
        justify-content: center;
        align-items: center;
        grid-column: 5;
        position: relative;
        width: 32px;
        min-width: 32px;
        height: 32px;
        border-radius: var(--border-radius-md);

        button:global(.cl-userButtonTrigger) {
          transition: var(--transition-md);
        }

        @media (max-width: 1200px) {
          grid-column: 3;
          grid-row: 1;
          // margin-left: var(--spacing-xxl);
        }

        // @media (max-width: 992px) {
        //   margin-left: var(--spacing-sm);
        //   margin-right: var(--spacing-sm);
        // }

        @media (max-width: 768px) {
          grid-column: 3;
          grid-row: 1;
          // margin-left: 0;
          margin-left: var(--spacing-md);
        }

        @media (max-width: 480px) {
          margin-right: var(--spacing-sm);
        }
      }
    }
  }
  .hamburgerMenu {
    position: absolute;
    right: var(--spacing-sm);
    top: 9px;

    @media (min-width: 1200px) {
      display: none;
    }
    @media (max-width: 768px) {
      right: var(--spacing-xs);
      top: 8px;
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
