.modalContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.64);
  z-index: 100;
  opacity: 0;
  transition: var(--transition-md);

  &.isOpen {
    animation: fadeIn 0.3s forwards;
  }

  &.isClosed {
    animation: fadeOut 0.3s forwards;
  }

  .modal {
    position: relative;
    display: flex;
    flex-direction: column;
    width: clamp(300px, 96%, 420px);
    height: 90%;
    border-radius: var(--border-radius-md);
    overflow-x: hidden;
    overflow-y: scroll;
    background: var(--light-background);

    .closeButton {
      position: absolute;
      top: var(--spacing-xs);
      right: var(--spacing-xs);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 8%;
      border: 0;
      margin-right: 2px;
      padding: var(--spacing-xxs) 0;
      background: transparent;
      border-radius: var(--border-radius-sm);
      font-size: var(--font-size-xxl);
      line-height: 50%;
      cursor: pointer;
      transition: var(--transition-md);

      .closeIcon {
        display: block;
        line-height: inherit;
        height: 100%;
      }
    }

    .scrollIndicatorContainer {
      position: sticky;
      display: flex;
      flex: 1;
      //   top: 100%;
      bottom: 0;
      width: 100%;
      height: 0;
      overflow: visible;

      .scrollIndicator {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 7vh;
        background: linear-gradient(
          to top,
          var(--light-background),
          transparent
        );
        z-index: 100;
        pointer-events: none;

        @container (height > 768px) {
          height: 0;
          display: none;
        }
      }
    }
  }
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--spacing-md);
  width: 88%;

  .tab {
    width: 50%;
    padding: var(--spacing-sm) var(--spacing-md);
    background: transparent;
    color: var(--dark-text);
    border-color: var(--dark-border);
    border-width: 0 0 1px 0;
    border-radius: 0;
    // border: none;
    transition: var(--transition-md);
    cursor: pointer;

    &:first-of-type {
      border-radius: var(--border-radius-md) 0 0 0;
    }

    // &:last-of-type {
    //   border-radius: 0 var(--border-radius-md) 0 0;
    // }

    &.active {
      background: var(--accent-1);
      color: var(--light-text);
    }
  }
}

.tabContent {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: var(--spacing-xxl);
  opacity: 0;
  animation: fadeIn 0.9s forwards;

  @media (max-width: 768px) {
    padding-bottom: var(--spacing-md);
  }

  &.isSwitching {
    animation: fadeOut 0.3s forwards;
  }

  :global {
    .cl-rootBox {
      .cl-cardBox {
        box-shadow: none;
      }
      .cl-card {
        margin: 0;
        padding: var(--spacing-xs);
        box-shadow: none;
        overflow-x: hidden;

        .cl-main {
          .cl-socialButtons {
            display: flex;
            span:first-of-type {
              margin: auto;
            }
            .cl-socialButtonsIconButton {
              width: 100%;
            }
            button {
              div {
                display: none;
              }
              svg {
                display: none;
              }
            }
          }
        }
        .cl-footer {
          display: none;

          + div {
            display: none;
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
