.userButton {
  max-width: max-content;
  .signInButton {
    position: relative;
    display: flex;
    align-items: center;
    width:100%;
    height: 100%;
    padding: 2px;
    border: none;
    border-radius: var(--border-radius-sm);
    color: var(--light-text);
    font-weight: 600;
    font-family: Gilroy;
    background: transparent;
    transition: var(--transition-md);

    &:hover {
      background: var(--accent-1);
      cursor: pointer;
    }
  }
  .accountButton {
    position: relative;
    pointer-events: none;

    .userIcon {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      transition: var(--transition-md);

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
    div:global(.cl-userButton-root) {
      pointer-events: auto;
    }
    img:global(.cl-userButtonAvatarImage) {
      opacity: 1;
      transition: var(--transition-md);

      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }
    }
  }
}
