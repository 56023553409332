.searchBoxContainer {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  .searchBox {
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    padding: var(--spacing-xs);
    padding-left: 35px;
    background: var(--light-background);
    font: var(--font-size-sm) inherit;
    font-size: var(--font-size-sm);

    &.rounded {
      border-radius: 100px;
    }

    &:focus {
      font-size: var(--font-size-sm);
    }
  }
  .submitIcon {
    position: absolute;
    left: 7px;
    width: 20px;
    display: flex;
    align-items: center;
    background: var(--light-background);
    border-radius: 100px;
    height: 100%;
  }
  .resetIcon {
    position: absolute;
    right: 10px;
    &:hover {
      cursor: pointer;
    }
    @media (max-width: 768px) {
      display: none;
    }
  }
}
