.searchBoxWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  // Uncomment to enable fade/grow animation to ease content flash
  // when the search loads after other header elements
  height: 100%;
  // opacity: 0;
  // width: 10%;
  // animation: growRight 0.7s ease-in forwards;
  // animation-delay: 0.3s;

  @media (max-width: 768px) {
    display: flex;
    width: 100%;
    background: white;
    border-radius: 5px;
    overflow: hidden;
  }
  .searchBox {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
    height: 100%;
    z-index: 50;

    @media (max-width: 768px) {
      flex: 1 1 auto;
    }
  }
  .closeButton {
    @media (min-width: 768px) {
      display: none;
    }

    @media (max-width: 768px) {
      flex: 0 1 35px;
      width: 35px;
      height: 35px;
      margin-right: 5px;
    }
  }
}
.headerSearchResults {
  position: fixed;
  top: 50px;
  left: 0;
  right: 0;
  width: auto;
  max-width: 1440px;
  margin: auto;
  background: var(--light-background);
  z-index: 100;
  box-shadow: 0px 2px 10px -2px var(--dark-background-translucent);
  @media (max-width: 768px) {
    top: 100px;
  }
}

@keyframes growRight {
  from {
    opacity: 0;
    width: 10%;
  }
  to {
    opacity: 1;
    width: 100%;
  }
}

// @keyframes hide {
//   from {
//     z-index:
//   } to {

//   }
// }

.hide {
  position: absolute;
  z-index: -1;
}
