.currencySwitcherButton {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 32px;
  height: 32px;
  // bottom: 5px;
  padding: 0;
  background: transparent;
  color: var(--light-text);
  border-radius: 0;
  z-index: 60;
  border: 0;
  transition: var(--transition-md);
  
  @media (min-width: 992px) {

    // padding-top: 10px;
  }
  &:hover {
    border-radius: var(--border-radius-sm);
  }
  &.active {
    background: #ffffff59 !important;
  }

  .buttonWrapper {
    @media (min-width: 992px) {
      position: relative;
      // bottom: 5px;
      display: flex;
      align-items: center;

    }

    .flag {
      display: inline-flex;
      align-items: center;
      width: 24px;
      height: 18px;
      // margin-left: 0.4em;
    }
  }
}
.currencySwitcher {
  position: absolute;
  width: 240px;
  top: 80px;
  right: 69px;
  // border: 1px solid var(--dark-border);
  margin-right: 10px;
  border-radius: 0 0 var(--border-radius-md) var(--border-radius-md);
  box-shadow: 3px 3px 10px 3px var(--mid-shadow);
  background: var(--light-background);
  color: var(--dark-text);
  transition: all 0.4s ease-in-out;
  &:focus,
  &:active {
    background: #ffffff59;
  }

  @media (max-width: 1200px) {
    top: 60px !important;
    // right: 0;
    left: 30%;
  }
  
  &.show {
    display: flex;
    transform: translate3d(0, -15px, 0);
    opacity: 1;
    z-index: 60;
  }
  &.hide {
    visibility: hidden;
    opacity: 0;
    transform: translate3d(0, -50px, 0);
    z-index: -1;
  }
  .currencyItems {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0;
    padding-top: var(--spacing-xs);
    .currencyItem {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      margin-left: 0;
      padding: 0.15em 0.5em;
      &:hover {
        cursor: pointer;
        opacity: 0.8;
      }

      .symbol {
        display: inline-block;
        min-width: 1em;
        margin-right: 0.4em;
      }

      .name {
        min-width: 2em;
      }
      .flag {
        display: flex;
        align-items: center;
        // min-width: 20px;
        // min-height: 10px;
        width: 20px;
        height: 13px;
        margin-left: auto;
      }
    }
  }
}
