.hamburgerMenu {
  .icon {
    position: relative;
    color: var(--light-text);
    z-index: 95;
    &:hover {
      cursor: pointer;
    }
  }
  .menuContent {
    position: fixed;
    width: 85%;
    max-width: 400px;
    height: 100%;
    top: 0;
    right: 0;
    padding-top: 50px;
    background: linear-gradient(
      to bottom,
      transparent 50px,
      var(--light-background) 50px
    );
    box-shadow: 2px 0px 5px 2px var(--dark-background);
    z-index: 80;
    overflow: auto;
    @media (max-width: 768px) {
      padding-top: 100px;
      background: linear-gradient(
        to bottom,
        transparent 100px,
        var(--light-background) 100px
      );
    }
    @media (min-width: 1200px) {
      top: 50px;
      padding-top: 0px;
    }

    &.open {
      opacity: 1;
      transform: translate3d(0%, 0, 0);
      transition: all 0.7s ease-out;
    }
    &.closed {
      opacity: 0;
      transform: translate3d(150%, 0%, 0);
      transition: all 0.5s ease-in;
    }
    .row1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: var(--spacing-sm) var(--spacing-xs) 0 var(--spacing-xs);

      .userButton {
        display: flex;
        justify-content: flex-end;
      }

      .currencySwitcher {
        position: relative;
        // padding: 0 var(--spacing-xs);
      }
    }

    .menuInfo {
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-left: calc(var(--spacing-xs) + 2px);

      // @media (min-width: 480px) {
      //   padding-left: calc(var(--spacing-xs) + 3px);
      // }
    }
  }
}
