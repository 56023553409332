.headerArea {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: var(--dark-background);
  z-index: 90;
}
.content {
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: 992px) {
    // margin-top: 100px;
  }
}

.webviewWarning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  padding: var(--spacing-xs) var(--spacing-sm);
  background: var(--accent-1);
  color: var(--light-text);
  z-index: 100;

  .close {
    button {
      &:hover {
        background: var(--light-background) !important;
        color: var(--dark-text) !important;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;

    .icon {
      margin-right: var(--spacing-sm);
    }

    .text {
      max-width: 768px;
    }
  }
}
