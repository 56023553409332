.container {
  max-width: 100%;
  .nav {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    padding-left: 0;
    margin: 0;

    &.desktopNav {
      @media (max-width: 1200px) {
        display: none;
      }
    }

    .menuItem {
      position: relative;
      top: 6px;
      display: flex;
      align-items: center;
      height: 100%;
      width: max-content;
      // margin: 0 var(--spacing-xxs) 0 var(--spacing-xxs);
      padding: 0 0 10px 0;
      border-radius: 3px;
      text-align: center;
      // font-family: "Gilroy";
      font-weight: 600;
      font-size: 16px;
      color: var(--light-text);
      line-height: 150%;
      .menuItemLink {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 6px;
        border-radius: 3px;
        color: var(--light-text);

        &:hover {
          background-color: rgba(255, 255, 255, 0.3490196078);
          cursor: pointer;
        }
      }
    }
    .subMenuParent {
      position: relative;
    }

    &.mobileNav {
      flex-direction: column;
      padding-top: var(--spacing-xs);

      .menuItem {
        border-bottom: 1px solid #ddd;
        width: 100%;
        padding: var(--spacing-xs) var(--spacing-sm);
        transition: all 0.3s ease-in-out;
        margin: 0;
        font-size: var(--font-size-md-sm);
        font-weight: 500;
        color: var(--dark-text);
        &:hover {
          cursor: pointer;
          padding-left: var(--spacing-lg);
        }
        &:last-of-type {
          border-bottom: none;
        }
        .menuItemLink {
          color: var(--dark-text);
        }
        &.subMenuParent {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding-left: var(--spacing-md);
          transition:
            min-height 0.7s linear,
            padding-left 0.3s ease-in-out;
          min-height: 0;

          &:hover {
            padding-left: var(--spacing-lg);
          }
          span {
            display: flex;
            align-items: center;
            font-weight: inherit;
            font-size: inherit;
          }
          &.active {
            padding-left: var(--spacing-lg);
            min-height: fit-content;
          }
          .menuArrow {
            position: relative;
            top: -2px;
            transform: rotate(90deg);
            transition: all 0.5s ease-in-out;

            &.activeArrow {
              transform: rotate(-90deg);
            }
          }
        }
      }
      .subMenu {
        width: 100%;

        &.open {
          position: relative;
          opacity: 1;
          height: 100%;
          transform: translate3d(0%, 0, 0);
          transition: all 0.7s ease-out;
        }
        &.closed {
          opacity: 0;
          position: relative;
          height: 0;
          transform: translate3d(150%, 0%, 0);
          transition: all 0.5s ease-in;
        }

        .icon {
          text-align: right;
          padding-right: var(--spacing-sm);

          &:hover {
            cursor: pointer;
          }
        }
        ul {
          padding: 0;
          .menuItem {
            padding: var(--spacing-xxs);
            margin-left: var(--spacing-md);
            font-size: var(--font-size-sm);
            text-transform: capitalize;

            &:hover {
              padding-left: var(--spacing-sm);
            }

            .subMenuLink {
              position: relative;
            }
          }
        }
      }
    }
  }

  @keyframes slideDown {
    from {
      position: absolute;
      opacity: 0;

      transform: translate3d(0, -150%, 0);
    }
    to {
      position: relative;
      opacity: 1;

      transform: translate3d(0, 0%, 0);
    }
  }

  @keyframes slideUp {
    0% {
      position: relative;
      opacity: 1;

      transform: translate3d(0, 0, 0);
    }
    100% {
      position: absolute;
      opacity: 0;

      transform: translate3d(0, -150%, 0);
    }
  }
}
